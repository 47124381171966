<template>
  <nav>
    <div class="navigation">
      <router-link to="/" id="header-name"><span id="logo_text">#</span> Samuel Schneider</router-link>
      <div class="align-right"> 
        <router-link to="/">{{$t('aboutme.header')}}</router-link>
        <a v-if="showHyperlink" @click="scrollToElement('info')">{{$t('aboutme.info')}}</a>
        <a v-if="showHyperlink" @click="scrollToElement('experience')">{{$t('experience.header')}}</a>
        <a v-if="showHyperlink" @click="scrollToElement('contact')">{{$t('contact.header')}}</a>
        <div class="dropdown">
          <button class="dropbtn">{{languageText}}</button>
          <div class="dropdown-content">
            <a @click="$i18n.locale = 'de'">{{$t('language.german')}}</a>
            <a @click="$i18n.locale = 'en'">{{$t('language.english')}}</a>
          </div>
        </div>
      </div>
    </div>

  </nav>
  <router-view/>
  <BodenLos />

</template>

<script>
import BodenLos from "./components/BodenLos.vue";
document.title = 'Samuel Schneider | Portfolio'

export default{
  components:{
    BodenLos
  },

  methods: {
    scrollToElement(element){
      const contact = document.getElementById(element);
      if(contact != null){
        contact.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },

  computed: {
    currentRouteName() {
        return this.$route.name;
    },
    showHyperlink(){
      return this.currentRouteName === 'about';
    },
    languageText(){
      return this.$i18n.locale === 'de'? "DE" : "EN";
    }
  }

}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200&display=swap');


@font-face {
  font-family: "AvenirLight";
  src: local("AvenirLight"),   url(./fonts/AvenirLight.ttf) format("truetype");
}

@font-face {
  font-family: 'KgHappy';
  src: local("KgHappy"), url(./fonts/KgHappy-wWZZ.ttf) format("truetype");
}

#app {
  font-family: Avenir Light, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background: darkslategray;
  min-width: 900px;
}

body, html{
  margin: 0;
  padding: 0;
  height: 100%;
  background: #333b50;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #394257;
  z-index: 10000;
}

.navigation{
  display:inline-flex;
  justify-content: space-between;
  width: 100%;
}

nav a {

  font-weight: bold;
  text-decoration-line: none;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
}

.align-right{
  display:inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10%;
  float:right;
}

nav a:hover{
  color: #42b983;
}

#logo_text{
  color: red;
  font-family: KgHappy;
  font-size: 200%;
  padding: 0;
  margin: 0;
  margin-right: 5px;
}
#header-name{
  color: whitesmoke;
  margin-left:3%;
  font-size: x-large;
  display:inline-flex;
  align-items: center;

}


p{
    font-size: x-large;
}

h1{
    font-size: 60px;
}

/* Dropdown button */
.dropdown .dropbtn {
  border: 1px solid white;
  color: white;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #394257;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}



</style>

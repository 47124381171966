export default {
  "aboutme": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a software tester, developer and dual student at Deutsche Telekom AG. I am currently studying computer science at the Provadis School of International Management & Technology AG in Frankfurt."])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "age_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "info_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer science"])}
  },
  "experience": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "work_exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work experience"])},
    "work_exp_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT System Operations Center of the German Armed Forces, Rheinbach"])},
    "abitur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abitur"])},
    "abitur_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Städtisches Gymnasium Rheinbach"])},
    "dual_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dual study program"])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to contact me?"])},
    "info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just try via email, phone or the form."])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+49 0160 95562568"])},
    "submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "submit_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message was sent successfully"])},
    "submit_bad_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently not available. Please try via email."])}
  },
  "projects": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])}
  },
  "legal_stuff": {
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal notice"])},
    "data_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data privacy"])},
    "site_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site operator/ Person in charge"])},
    "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact data"])},
    "header_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
    "text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the following data protection declaration, we would like to inform you about the types of your personal data (hereinafter also referred to as \"data\") that we process, for what purposes and to what extent. The data protection declaration applies to all processing of personal data carried out by us, both as part of the provision of our services and, in particular, on our websites, in mobile applications and within external online presences, such as our social media profiles (hereinafter collectively referred to as \"online offer\"). \n The terms used are not gender-specific."])},
    "header_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data deletion"])},
    "text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data processed by us will be deleted in accordance with the legal requirements as soon as their consents permitted for processing are revoked or other permissions cease to apply (e.g. if the purpose of processing this data has ceased to apply or it is not required for the purpose).\nIf the data is not deleted because it is required for other and legally permissible purposes, its processing will be limited to these purposes. That is, the data will be blocked and not processed for other purposes. This applies, for example, to data that must be retained for reasons of commercial or tax law or whose retention is necessary for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural person or legal entity.\nOur data protection notices may also contain further details on the retention and deletion of data that have priority for the respective processing operations."])},
    "header_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form"])},
    "text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When contacting us (e.g. by contact form, e-mail, telephone or via social media) as well as in the context of existing user and business relationships, the information of the inquiring persons is processed to the extent necessary to respond to the contact inquiries and any requested measures. The response to the contact inquiries as well as the management of contact and inquiry data in the context of contractual or pre-contractual relationships is carried out to fulfill our contractual obligations or to respond to (pre)contractual inquiries and otherwise on the basis of legitimate interests in responding to inquiries and maintaining user or business relationships."])},
    "list_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of data processed: inventory data (e.g. names, addresses); contact data (e.g. e-mail, telephone numbers); content data (e.g. entries in online forms)."])},
    "list_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affected persons: Communication partners."])},
    "list_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purposes of processing: contact requests and communication; provision of contractual services and customer service."])},
    "list_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal basis: Contract fulfillment and pre-contractual requests (Art. 6 para. 1 p. 1 lit. b. DSGVO); Legitimate interests (Art. 6 para. 1 p. 1 lit. f. DSGVO); Legal obligation (Art. 6 para. 1 p. 1 lit. c. DSGVO)."])},
    "header_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further information on processing, procedures and services:"])},
    "text_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form: If users contact us via our contact form, e-mail or other communication channels, we process the data communicated to us in this context for the purpose of processing the communicated request. For this purpose, we process personal data in the context of pre-contractual and contractual business relationships, insofar as this is necessary for their fulfillment, and otherwise on the basis of our legitimate interests as well as the interests of the communication partners in responding to the concerns and our statutory retention obligations."])}
  },
  "language": {
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  }
}
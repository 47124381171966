<template>
    <TemplateOne :header=first_header :text=first_text :img=img />
    <TemplateTwo :header=second_header :text=second_text />
    <LebenslaufPfad />
    <ContactInformation />
</template>

<script>
import TemplateOne from '../components/Template_one.vue'
import TemplateTwo from '../components/PersonalInformation.vue'
import LebenslaufPfad from '../components/LebenslaufPfad.vue'
import ContactInformation from "../components/ContactInformation.vue";


export default {
    components: {
        TemplateOne,
        TemplateTwo,
        LebenslaufPfad,
        ContactInformation
    }
}
</script>

<style>

</style>
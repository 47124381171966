<template>
    <div class="template" id="contact"> 
        <img src="../assets/blue-pattern-two.jpg"/>
        <div class="grid-container">
            <div class="grid-left">
            <h1 class="header">{{$t('contact.header')}}</h1>
            <p class="text">{{$t('contact.info')}} <br> <br> {{$t('contact.info_2')}}</p>
            <p class="data">{{email}}</p>
            <p class="data">Tel.: {{$t('contact.tel')}} </p>
            </div>

            <div class="grid-right"> 
                <input id="name" type="text" v-model="contact_name" placeholder="Name" />
                <input id="email" v-model="contact_email" placeholder="E-Mail" />
                <input id="tel" v-model="contact_tel" placeholder="Tel." >
                <textarea id="message" v-model="contact_message" placeholder="Information" />
                <button id="btn_submit" @click="submitForm()">{{$t('contact.submit_text')}}</button>
            </div>

        </div>
    </div>
    <Transition>
        <div class ="response" id="response" v-if="showResponse"> 
            <p>{{$t('contact.submit_result')}}</p>
        </div>
    </Transition>
    <Transition>
      <div class ="response" id="bad_response" v-if="showBadResponse">
        <p>{{$t('contact.submit_bad_result')}}</p>
      </div>
    </Transition>
  
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            email: "contact@schneider-samuel.de",

            contact_name: "",
            contact_email: "",
            contact_tel: "",
            contact_message: "",

            showResponse: false,
            showBadResponse: false
        }
    },
    methods: {
      async submitForm() {
        const instance = axios.create({
          baseURL: 'https://schneider-samuel.de/api',
        });

        const to = "contact@schneider-samuel.de"
        const subject = "Nachricht von deiner Webpage"
        const message = "Hi, du hast eine Nachricht von deiner Website :) \n\n " + this.contact_name + " \n " + this.contact_email + " \n " + this.contact_tel + " \n\n " + this.contact_message
        await instance.post('/send-email', {
          to,
          subject,
          message
        }).then(() => {
          this.showResponse = true
          setTimeout(() => {
            this.showResponse = false
          }, 5000)
        }).catch(() => {
          this.showBadResponse = true
          setTimeout(() => {
            this.showBadResponse = false
          }, 5000)
        });

        // Reset form field
        this.contact_name = ''
        this.contact_email = ''
        this.contact_tel = ''
        this.contact_message = ''
      }
    },

      computed: {
          get_text_tel(){
            console.log(this.t('experience.tel_text'));
              return this.parent.t('experience.tel_text');
          }
      }
}

</script>

<style scoped>

.template{
    height:400px;
}

img{
    position: absolute;
    left:0;
    opacity: 15%;
    width: 100%;
    min-width: 900px;
    height: 400px;
}

.grid-container{
    position: relative;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    height: 500px;
    justify-content: center;
    display: grid;
    background: #333b50;
    grid-template-columns: 350px 450px;
    font-family: AvenirLight, sans-serif;
    color: white;
}

.grid-left{
    grid-column-start: 1;
    grid-column-end: 2;
    text-align: start;
    margin-top:100px;
    margin-left: 50px;
    padding-right: 50px;
}

.header{
    font-size: xx-large;
    margin-bottom: 20px;
    margin-top:0;
}

.text{
    font-size: medium;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 1.9em;
}

.data{
    font-size: medium;
    margin-top: 10px;
    margin-bottom: 10px;
}


.grid-right{
    grid-column-start: 2;
    grid-column-end: 3;
    margin-top:100px;
    margin-right: 50px;
    justify-self: right;
}

#name, #email{
    width: 155px;
    height: 35px;
    margin-left:5px;
    margin-right:5px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

#tel{
    width: 320px;
    height: 35px;
    margin-bottom:10px;
    box-sizing: border-box;
}

#message{
    width: 320px;
    height: 150px;
    padding: 5px;
    resize: none;
    box-sizing: border-box;
}

#btn_submit{
    width: 320px;
    height: 45px;
    margin-left: 0;
    margin-right: 0;
    margin-top:10px;
    border: none;
    background-color: #00d646;
    transition: background-color 0.5s;
    color: white;
}

#btn_submit:hover{
    background-color:#5f6880;
}

.response{
    position: fixed;
    top: 120px;
    right: 50px;
    width: 250px;
    height: 70px;
    border-style: none;
    border-radius: 10px;
    background: #00d646;
    display: flex;
}

#bad_response{
    background: #FF0000;
}

.response p{
    font-size: 20px;
    color: white;
    align-self: center;
}

.v-enter-active{
    transition: right 0.5s;
}
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from{
    right: 0px;
}
.v-leave-to {
    opacity: 0;
}


</style>
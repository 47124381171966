<template>
    <div class="container">  
        <div class="content">
            <h1>{{$t('legal_stuff.impressum')}}</h1>

            <h2>{{$t('legal_stuff.site_operator')}}</h2>
            <p>Samuel Schneider</p>

            <h2>{{$t('legal_stuff.contact_data')}}</h2>
            <p>Prälat-Koch-Str. 21<br>53359 Rheinbach</p>
            <p>+49 0160 95562568</p>
            <p class="lastElement">sam@gms.fyi</p>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>

.container{
    display: block;
    background: #333b50;
    height: calc(100vh - 100px);
    width: 100%;
}

p{
    color: white;
    font-size: 20px;
}

h1{
    color:white;
    padding-top: 100px;
    margin-top: 0;
}

h2{
    color: white;
    font-size: 20px;
    padding-top: 50px;
}

.lastElement{
    margin-bottom: 0;
    padding-bottom: 100px;
}

</style>
<template>
  <div class="grid-container">
    <div class="grid-header">
    <h1>{{$t('aboutme.header')}}</h1>
    </div>
    
    <div class="grid-text">
        <p>{{$t('aboutme.text')}}</p>
    </div>

    <div class="grid-img">
        <img :src="require('@/assets/' + 'foto.jpg')" rel="preload">
    </div>

  </div>
</template>

<script>
</script>

<style scoped>

.grid-container{
    display: grid;
    margin-top: 20px;
    background: white;
    grid-template-columns: 50% 50%;
    justify-content: center;
    font-family: 'Work Sans', sans-serif;
}

.grid-header{
    grid-column-start: 1;
    grid-column-end: 2;
    align-self: end;
    justify-self: start;
    margin-left: 30%;
}

.grid-text{
    grid-column-start: 1;
    grid-column-end: 2;
    justify-self: start;
    margin-left: 30%;
    text-align: start;
}


.grid-img{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start:1;
    grid-row-end:3;
    justify-self: start;
    align-self: center;
    margin-left: 30%;
    width: auto;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 25%;
}

img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

</style>
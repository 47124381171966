<template>
  <div class="lebenslauf-container" id="experience"> 
    <img src="../assets/mountain.jpg"/>

    <h1>{{$t('experience.header')}}</h1>

    <svg xmlns="http://www.w3.org/2000/svg">
      <line x1="50%" x2="50%" y1="50px" y2="850px" stroke-width="2" stroke="#00d646"/>
      <circle cx="50%" cy="50px" r="7px" fill="#00d646"/>
      <circle cx="50%" cy="250px" r="7px" fill="#00d646"/>
      <circle cx="50%" cy="450px" r="7px" fill="#00d646"/>
      <circle cx="50%" cy="650px" r="7px" fill="#00d646"/>
    </svg>

  <div class="grid-layout-lebenslauf"> 
    
    <div class="lebenslauf-column-two">
      <p class="time">2021 - 2024</p>
      <p class="header">{{$t('experience.dual_study')}}</p>
      <p class="text">Deutsche Telekom AG</p>
    </div>

    <div class="lebenslauf-column-one">
      <p class="time">2021</p>
      <p class="header">{{$t('experience.abitur')}}</p>
      <p class="text">{{$t('experience.abitur_location')}}</p>
    </div>

    <div class="lebenslauf-column-two"/>

    <div class="lebenslauf-column-two">
      <p class="time">2019</p>
      <p class="header">{{$t('experience.work_exp')}}</p>
      <p class="text">{{$t('experience.work_exp_location')}}</p>
    </div>

    <div class="lebenslauf-column-one">
      <p class="time">2018</p>
      <p class="header">{{$t('experience.work_exp')}}</p>
      <p class="text">BWI GmbH</p>
    </div>
    

  </div>
    

  </div>
 

</template>

<script>

</script>

<style scoped>

.lebenslauf-container{
  min-width: 900px;
  height: 1400px;
}

.lebenslauf-container img{
  position: absolute;
  left:0;
  opacity: 15%;
  width: 100%;
  min-width: 900px;
  height: 1400px;
}

svg{
  position: absolute;
  left:0;
  height: 950px;
  width: 100%;
  min-width: 900px;

}

h1{
  color:white;
  padding-top:100px;
  margin-top: 0;
}

.grid-layout-lebenslauf{
  display: grid;
  margin-top: 60px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 200px;
  justify-content: center;
  color: white;
}

.lebenslauf-column-one{
  grid-column-start: 1;
  grid-column-end: 2;
  height: 200px;
  align-self: start;
  justify-self: end;
  margin-right:30px
}

.lebenslauf-column-two{
  grid-column-start: 2;
  grid-column-end: 3;
  height: 200px;
  align-self: start;
  justify-self: start;
  margin-left:30px
}

.lebenslauf-column-two p{
  text-align: left;
}

.lebenslauf-column-one p{
  text-align: right;
}

.header{
  font-size: xx-large;
  margin-bottom: 10px;
  margin-top: 10px;
}

.text{
  font-size: medium;
  opacity: 80%;
  margin-top:10px
}

.time{
  color: #00d646;
  font-size: 20px;
  margin-bottom: 10px;
}






</style>


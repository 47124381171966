<template>
    <div class="container">  
        <div class="content">
            <h1>{{$t('legal_stuff.data_privacy')}}</h1>
            <h2>{{$t('legal_stuff.site_operator')}}</h2>
            <p>Samuel Schneider</p>

            <h2>{{$t('legal_stuff.contact_data')}}</h2>
            <p>Prälat-Koch-Str. 21<br>53359 Rheinbach</p>
            <p>+49 0160 95562568</p>
            <p>sam@gms.fyi</p>

            <h2>{{$t('legal_stuff.header_one')}}</h2>
            <p>{{$t('legal_stuff.text_one')}}</p>
            <h2>{{$t('legal_stuff.header_two')}}</h2>
            <p>{{$t('legal_stuff.text_two')}}</p>
            <h2>{{$t('legal_stuff.header_three')}}</h2>
            <p>{{$t('legal_stuff.text_three')}}</p>
            <ul>
                <li>{{$t('legal_stuff.list_one')}}</li>
                <li>{{$t('legal_stuff.list_two')}}</li>
                <li>{{$t('legal_stuff.list_three')}}</li>
                <li>{{$t('legal_stuff.list_four')}}</li>
            </ul>

            <h2>{{$t('legal_stuff.header_four')}} </h2>

            <p>{{$t('legal_stuff.text_four')}}</p>
        </div>
    </div>

</template>

<script>

</script>

<style scoped>

.container{
    background: #333b50;
    display: flex;
    flex-direction: column;
}

.content{
    padding-top: 100px;
    padding-left: 200px;
    padding-right: 200px;
    width: 500px;
    align-self: center;
}

p{
    color: white;
    font-size: 20px;
}

h1{
    color:white;
}

h2{
    color: white;
    padding-top: 50px;
}

li{
    color: white;
    font-size: large;
    text-align: start;
    padding: 5px;
}

</style>



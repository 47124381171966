<template>
  <div class="grid-container" id="info">
    <div class="grid-header">
        <h1 class="header">{{$t('aboutme.info')}}</h1>
    </div>
        
    <div class="grid-content">
        <div class="info header">
            <img src="../assets/person-icon.png"/> 
            <p>Samuel Schneider</p>  
        </div>
        <div class="info">
            <img src="../assets/age-icon.png"/> 
            <p>{{calculateAge}} {{$t('aboutme.age_text')}}</p>
        </div>
        <div class="info">
            <a href="https://www.google.com/maps/place/Bonn/@50.7034931,6.9772171,11z/data=!3m1!4b1!4m5!3m4!1s0x47bee19f7ccbda49:0x86dbf8c6685c9617!8m2!3d50.73743!4d7.0982068" target="_blank" rel="noopener noreferrer">
                <img src="../assets/location-pointer.png"/> 
            </a>
            <p>Bonn, Germany</p>  
        </div>
        <div class="info">
            <img src="../assets/studium-icon.png"/> 
            <p>{{$t('aboutme.info_study')}}</p>
        </div>          
    </div>
  </div>
</template>

<script>
export default {
    computed:{
        calculateAge: function() {
          let currentDate = new Date();
          let birthDate = new Date("2002/11/12");
          let difference = currentDate - birthDate;
          return Math.floor(difference / 31557600000)
        }
    },
    props:[
        'header',
        'text'
    ]
}
</script>

<style scoped>

.grid-container{
    display: grid;
    background: #f2ede9;
    grid-template-columns: 50% 50%;
    font-family: 'Work Sans', sans-serif;
    justify-content: center;
}

.grid-header{
    grid-column-start: 1;
    grid-column-end: 2;
    margin-left: 30%;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-self: start;
}

.grid-content{
    grid-column-start: 2;
    grid-column-end: 3;
    margin-right: 20%;
    text-align: start;
    justify-self: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.header{
    margin-top: 20px;
}


.info{
    display: flex;
    align-items: center;
}

.info img{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.info a{
    font-style: none;
}

</style>
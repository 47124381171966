<template>
    <div class="container">
        <div class="inner-container">
            <router-link to="/impressum">{{$t('legal_stuff.impressum')}}</router-link>
            <router-link to="/datenschutz">{{$t('legal_stuff.data_privacy')}}</router-link>
        </div>
        <p>© {{ new Date().getFullYear() }} Samuel Schneider </p>
    </div>
</template>

<script>

export default {
}
</script>

<style scoped>

.container{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    background: #394257;
    color: white;
    margin-bottom: 0px;
    align-items: center;
    justify-content: center;
}

.inner-container{
    display: inline-flex;
    justify-content: center;
    margin-bottom: 0px;
}

a, p{
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top: 15px;
    color:white;
    text-decoration: none;
}

p{
    margin-top: 5px;
}

a.router-link-exact-active {
  color: #42b983;
}

a:hover{
    color: #42b983;
}


</style>
export default {
  "aboutme": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über mich"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin Softwaretester, Entwickler und dualer Student bei der Deutschen Telekom AG. Zur Zeit studiere ich Informatik an der Provadis School of International Management & Technology AG in Frankfurt."])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "age_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])},
    "info_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatik"])}
  },
  "experience": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrung"])},
    "work_exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktikum"])},
    "work_exp_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebszentrum IT-System der Bundeswehr, Rheinbach"])},
    "abitur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abitur"])},
    "abitur_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Städtisches Gymnasium Rheinbach"])},
    "dual_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duales Studium"])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie mich kontaktieren?"])},
    "info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach über E-Mail, Telefon oder das Formular versuchen."])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+49 0160 95562568"])},
    "submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
    "submit_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht erfolgreich gesendet."])},
    "submit_bad_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Zeit nicht nutzbar. Versuchen Sie es bitte per E-Mail."])}
  },
  "projects": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])}
  },
  "legal_stuff": {
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "data_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "site_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenbetreiber/ Verantwortlicher"])},
    "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktdaten"])},
    "header_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einleitung"])},
    "text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als \"Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als \"Onlineangebot“). \n Die verwendeten Begriffe sind nicht geschlechtsspezifisch."])},
    "header_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen von Daten"])},
    "text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).\nSofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.\nUnsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten."])},
    "header_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktformular"])},
    "text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.\n\nDie Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen und Pflege von Nutzer- bzw. Geschäftsbeziehungen."])},
    "list_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen)."])},
    "list_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betroffene Personen: Kommunikationspartner."])},
    "list_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation; Erbringung vertraglicher Leistungen und Kundenservice."])},
    "list_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)."])},
    "header_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:"])},
    "text_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktformular: Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens. Zu diesem Zweck verarbeiten wir personenbezogene Daten im Rahmen vorvertraglicher und vertraglicher Geschäftsbeziehungen, soweit dies zu deren Erfüllung erforderlich ist und im Übrigen auf Grundlage unserer berechtigten Interessen sowie der Interessen der Kommunikationspartner an der Beantwortung der Anliegen und unserer gesetzlichen Aufbewahrungspflichten."])}
  },
  "language": {
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])}
  }
}